import Masonry from 'masonry-layout';
import offsetScroll from './util/offsetScroll';
import { header, hero, scrollSection } from './util/config';

($ => {
  /**
   * Mobile nav
   */
  document.getElementById('mobileToggle').addEventListener('click', () => {
    if (header.classList.contains('mobile-nav-triggered')) {
      header.classList.remove('mobile-nav-triggered');
    } else {
      header.classList.add('mobile-nav-triggered');
    }
  });

  /**
   * Sticky header
   */
  window.addEventListener('scroll', () => {
    if (window.scrollY >= hero.offsetHeight - header.offsetHeight) {
      header.classList.add('header--scrolled');
    } else {
      header.classList.remove('header--scrolled');
    }
  });

  /**
   * Lightcase galleries
   */
  $('a[data-rel^=lightcase]').lightcase({
    showCaption: false,
    showTitle: false,
    showSequenceInfo: false,
  });

  /**
   * Reviews masonry layout
   */
  if (document.getElementsByClassName('reviews--masonry')[0]) {
    const reviewsMasonry = new Masonry('.reviews--masonry', {
      itemSelector: '.review',
    });
  }

  /**
   * Kite Festival countdown on homepage
   */
  if (document.getElementsByClassName('kite-festivals--countdown')[0]) {
    $('.kite-festivals--countdown').TimeCircles({
      use_background: true,
      circle_bg_color: '#cccccc',
      fg_width: 0.05,
      time: {
        Days: { color: '#231f64' },
        Hours: { color: '#231f64' },
        Minutes: { color: '#231f64' },
        Seconds: { color: '#231f64' },
      },
    });
  }

  /**
   * Homepage Map
   */
  if (document.getElementsByClassName('main__home__location-map__cover')[0]) {
    const mapcover = document.getElementsByClassName('main__home__location-map__cover')[0];
    mapcover.addEventListener('click', () => {
      mapcover.style.display = 'none';
    });
  }

})(jQuery);
